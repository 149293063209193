let MaterialLibrary = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 当前机构素材列表
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.OrganizaionList = function (pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/MaterialLibrary/Organizaion';
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 新增、编辑相册
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.EditAlbum = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/MaterialLibrary';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 指定相册图片列表
     * @param organizationId
     * @param materialLibraryId
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.LibraryFiles = function (organizationId, materialLibraryId, pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/MaterialLibrary/LibraryFiles';
        if (organizationId) {
            url += `/${organizationId}`;
        }
        if (materialLibraryId) {
            url += `/${materialLibraryId}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 删除素材图片
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.Remove = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/MaterialLibrary?fileIds=' + item
        TokenClient.Delete(url, true, null, null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )

    }

    /**
     * 相册上传图片
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.UploadImg = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/MaterialLibrary/AddFiles';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}
export {MaterialLibrary}
